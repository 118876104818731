<template>
  <div class="AfterSaleQuizReport" v-loading="loading">
    <div class="row">
      <div class="col-11 col-sm-6 col-md-4">
        <StatsCard
          :title="`${orders.length}`"
          :sub-title="$t('responses')"
          type="warning"
          :icon="'fas fa-question'"
        />
      </div>
      <div class="col text-right">
        <OrderFilterButton
          :initial-filter="filter"
          @filter="runSearch"
          :additional-date-options="additionalDateOptions"
        />
      </div>
    </div>

    <div class="row mb-5">
      <div :class="`col-${12 / chartsPerRow} mb-5`" v-for="chart in charts">
        <PieChart :chart-data="chart.data" :extra-options="chart.options" />
      </div>
    </div>

    <div class="Notes">
      <h3>{{ $t('notes') }}</h3>
      <div class="Note" v-for="(order, idx) in ordersWithNotes" :key="order.id">
        <div class="row mb-2">
          <div class="col">
            <Badge class="mr-2">
              {{ order.orderCode }}
            </Badge>
            <small
              ><b>{{ order.customer.name }}</b></small
            >
            <Badge class="pull-right">
              {{ order.date | formatDateTime('date') }}
            </Badge>
          </div>
        </div>
        <pre>
          {{ order.afterSaleQuiz.props.answers.notes }}
        </pre>
        <hr v-if="idx !== ordersWithNotes.length - 1" />
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from 'components/Charts/PieChart';
import { QuizUi } from '@/api/Models';
import StatsCard from '@/components/Cards/StatsCard';
import OrderFilterButton from '@/pages/funnel/OrderFilterButton';
import AppFilterDatePicker from '@/components/app/input/AppFilterDatePicker';
import Badge from '@/components/Badge';

export default {
  name: 'AfterSaleQuizReport',
  components: { Badge, OrderFilterButton, StatsCard, PieChart },
  data() {
    return {
      loading: false,
      filter: new OrderFilterButton.OrderFilterButtonModel({
        date: {
          field: 'afterSaleQuiz.updatedAt',
          range: [moment().subtract(3, 'months').startOf('month'), moment().endOf('month')],
        },
      }),
      chartsPerRow: 2,
      charts: {},
      orders: [],
      additionalDateOptions: [{ value: 'afterSaleQuiz.updatedAt', label: this.$t('answerDate') }],
    };
  },
  methods: {
    async runSearch(filter) {
      this.filter = filter;
      await this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      try {
        const filters = {};

        Object.assign(
          filters,
          AppFilterDatePicker.formatAsFilter(this.filter.date.field, this.filter.date.range)
        );

        let orders = await this.$api.Order.findAll({
          eager:
            '[items.[product, attributes], shipments, payments, customer.[contacts], status, layoutRequests, afterSaleQuiz, appliedCoupons]',
          'afterSaleQuizId:notNull': '',
          'afterSaleQuiz.closed': true,
          orderByDesc: 'date',
          ...filters,
        });

        if (this.filter.isAnyFilterSelected()) {
          orders = this.filter.filterOrders(orders);
        }

        this.chartsPerRow = Math.max(1, Math.floor(window.innerWidth / 500));

        this.orders = orders;

        const answerProps = [
          'orderSource',
          'productPrice',
          'freightCost',
          'attendanceSpeed',
          'productQuality',
          'productionSpeed',
          'shoppingExperience',
          'customizationQuality',
        ];

        const colors = {
          3: ['#E74C3C', '#F1C40F', '#16A085'],
          4: ['#E74C3C', '#F1C40F', '#2471A3', '#16A085'],
          5: ['#E74C3C', '#E67E22', '#F1C40F', '#2471A3', '#16A085'],
          10: [
            '#E74C3C',
            '#EC7063',
            '#E67E22',
            '#EB984E',
            '#F1C40F',
            '#F7DC6F',
            '#5499C7',
            '#2471A3',
            '#45B39D',
            '#16A085',
          ],
        };

        let manyColors = [
          '#003f5c',
          '#00876c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#78a97f',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
          '#4e9873',
          '#9dba8f',
          '#bfcca3',
          '#dddebb',
          '#f9f1d6',
          '#f0d8b1',
          '#eabe90',
          '#e28461',
          '#dc6455',
          '#d43d51',
        ];

        const results = answerProps.reduce((acc, val) => {
          acc[val] = {};
          return acc;
        }, {});

        QuizUi.Type.AfterSaleQuiz.orderSourceOptions.forEach(
          (value) => (results['orderSource'][value] = 0)
        );

        QuizUi.Type.AfterSaleQuiz.productPriceOpts.forEach(
          (value) => (results['productPrice'][value] = 0)
        );

        QuizUi.Type.AfterSaleQuiz.freightCostOptions
          .slice()
          .reverse()
          .forEach((value) => (results['freightCost'][value] = 0));

        _.range(10, 1).forEach((value) => {
          results['productQuality'][value] = 0;
        });

        QuizUi.Type.AfterSaleQuiz.attendanceSpeedOptions.forEach((value) => {
          results['attendanceSpeed'][value] = 0;
          results['productionSpeed'][value] = 0;
        });

        QuizUi.Type.AfterSaleQuiz.shoppingExperienceOptions.forEach(
          (value) => (results['shoppingExperience'][value] = 0)
        );

        QuizUi.Type.AfterSaleQuiz.customizationQualityOptions.forEach(
          (value) => (results['customizationQuality'][value] = 0)
        );

        for (const order of orders) {
          const { answers } = order.afterSaleQuiz.props;

          for (const answerProp of answerProps) {
            let value = answers[answerProp];
            if (value) {
              const result = results[answerProp];
              if (!result[value]) {
                result[value] = 0;
              }
              result[value]++;
            }
          }
        }

        const newCharts = {};
        for (const answerProp of answerProps) {
          const resultAnswers = results[answerProp];
          const keys = Object.keys(resultAnswers);
          newCharts[answerProp] = {
            options: {
              name: this.$t(`${answerProp}Label`),
              title: {
                display: true,
                text: this.$t(`${answerProp}Label`),
                fontSize: 24,
                fontStyle: 'normal',
                fontColor: 'rgba(255, 255, 255, 0.8)',
              },
              legend: {
                position: 'bottom',
                labels: {
                  fontSize: 11,
                  boxWidth: 12,
                  fontColor: 'rgba(255, 255, 255, 0.8)',
                },
              },
            },
            data: {
              labels: keys.map((key) => (Number.isFinite(Number(key)) ? key : this.$t(key))),
              datasets: [
                {
                  data: Object.values(resultAnswers),
                  backgroundColor: colors[keys.length] || manyColors,
                  borderColor: '#1e1e2f',
                  borderWidth: 1,
                },
              ],
            },
          };
        }

        this.charts = newCharts;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ordersWithNotes() {
      return this.orders.filter((order) => !_.isEmpty(order.afterSaleQuiz.props.answers.notes));
    },
  },
  async mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
.AfterSaleQuizReport {
  .Notes {
    .Note {
      pre {
        white-space: pre-line;
      }
    }
  }
}
</style>
