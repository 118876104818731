import Axios from 'axios';
import axiosRetry, { isNetworkError } from 'axios-retry';
import { appStore } from 'state/Store';

const $http = Axios.create({
  baseURL: '/',
});

axiosRetry($http, {
  retries: 5,
  retryDelay: () => 1500,
  retryCondition: (error) => {
    if (isNetworkError(error)) {
      return true;
    }

    if (error?.response?.status === 503) {
      return true;
    }

    return false;
  },
});

$http.interceptors.request.use(function (config) {
  if (appStore.state.token) {
    config.headers['Authorization'] = `Bearer ${appStore.state.token}`;
  }
  return config;
});

$http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (_.get(error, 'response.status', 0) === 401) {
      appStore.commit('logout');
    }
    return Promise.reject(error);
  }
);

export const axiosInstance = () => $http;
