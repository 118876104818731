export default {
  sidebar: {
    title: 'Creative Tim',
    shortTitle: 'CT',
    dashboard: 'Dashboard',
    pages: 'Páginas',
    pricing: 'Preços',
    rtl: 'Suporte RTL',
    timeline: 'Linha do tempo',
    register: 'Registrar',
    lock: 'Bloqueio de tela',
    userProfile: 'Perfil do usuário',
    components: 'Componentes',
    multiLevelCollapse: 'Menu multinível',
    example: 'Página de exemplo',
    buttons: 'Botões',
    gridSystem: 'Grid System',
    panels: 'Painéis',
    sweetAlert: 'Sweet Alert',
    icons: 'Ícones',
    notifications: 'Notificações',
    typography: 'Tipografia',
    forms: 'Formulários',
    regularForms: 'Formulários Regulares',
    extendedForms: 'Formulários de validação',
    validationForms: 'Validation Forms',
    wizard: 'Wizard',
    tables: 'Tabelas',
    regularTables: 'Tabelas Regulares',
    extendedTables: 'Tabelas estendidas',
    paginatedTables: 'Tabelas com paginação',
    maps: 'Mapas',
    googleMaps: 'Google Maps',
    fullScreenMaps: 'Mapa em tela cheia',
    vectorMaps: 'Mapas Vetoriais',
    widgets: 'Widgets',
    charts: 'Gráficos',
    calendar: 'Calendário',
  },
  salesFunnel: 'Funil de vendas',
  tracking: 'Rastreamento',
  login: 'Login',
  logout: 'Sair',
  loginInvalidUserOrPassword: 'Usuário e/ou senha inválidos, tente novamente...',
  totalOrders: 'Total de pedidos',
  dashboard: 'Dashboard',
  funnelTracking: 'Funil de vendas | Rastreamento',
  business: 'Negócio',
  businesses: 'Negócios',
  integrations: 'Integrações',
  orderCreation: 'Criação de pedidos',
  wooCommerce: 'Woo Commerce',
  copyToClipboard: 'Copiar para o clipboard',
  clickToCopyToClipboard: 'Clique para copiar o texto para o clipboard',
  valueCopiedToClipboard: 'O texto foi copiado para o clipboard',
  id: 'Id',
  users: 'Usuários',
  name: 'Nome',
  email: 'E-mail',
  role: 'Papel',
  username: 'Usuário',
  ADMIN: 'Administrador',
  USER: 'Usuário',
  actions: 'Ações',
  edit: 'Editar',
  remove: 'Remover',
  areYouSure: 'Tem certeza?',
  thisOperationCannotBeReverted: 'Esta operação não pode ser revertida',
  yes: 'Sim',
  no: 'Não',
  cancel: 'Cancelar',
  objectRemovedWithSuccess: 'Objeto removido com sucesso',
  error: 'Erro',
  of: 'de',
  close: 'Fechar',
  password: 'Senha',
  confirmPassword: 'Confirmação de senha',
  user: 'Usuário',
  save: 'Salvar',
  new: 'Novo',
  selectOne: 'Selecione...',
  displayName: 'Nome (Visualização)',
  requestedPageNotFound: 'A página solicitada não foi encontrada',
  forbiddenAccess: 'Acesso negado',
  orderStatus: 'Status do pedido',
  description: 'Descrição',
  position: 'Posição',
  newStatus: 'Novo Status',
  moveOrdersBeforeRemoveStatus: 'Mova os pedidos para um outro status antes de removê-lo',
  wishToMoveOrderToNewStatus: 'Deseja mover o pedido {order} para o status: {status}',
  attention: 'Atenção',
  move: 'Mover',
  summary: 'Resumo',
  seeDetails: 'Ver detalhes',
  management: 'Gerenciamento',
  visible: 'Visível',
  true: 'Sim',
  false: 'Não',
  customers: 'Clientes',
  customer: 'Cliente',
  code: 'Código',
  firstName: 'Nome',
  lastName: 'Sobrenome',
  addresses: 'Endereços',
  contacts: 'Contatos',
  PHONE: 'Telefone',
  type: 'Tipo',
  content: 'Conteúdo',
  contact: 'Contato',
  address: 'Endereço',
  city: 'Cidade',
  state: 'Estado',
  cep: 'CEP',
  postalCode: 'CEP',
  postalCodeNotFound: 'CEP não encontrado',
  searchPostalCode: 'Consultar CEP',
  postalCodeFound: 'CEP encontrado',
  data: 'Dados',
  products: 'Produtos',
  product: 'Produto',
  price: 'Preço',
  sku: 'Sku',
  attributes: 'Atributos',
  attribute: 'Atributo',
  values: 'Valores',
  value: 'Valor',
  addNewItem: 'Novo item...',
  tagInputTitle: 'Digite algo e pressione Enter',
  shippingMethods: 'Meios de entrega',
  shippingMethod: 'Meio de entrega',
  orders: 'Pedidos',
  order: 'Pedido',
  createdAt: 'Data da criação',
  date: 'Data',
  visibles: 'Visíveis',
  delivery: 'Entrega',
  items: 'Itens',
  item: 'Item',
  orderItem: 'Item do pedido',
  quantity: 'Quantidade',
  total: 'Total',
  cpf: 'CPF',
  neighborhood: 'Bairro',
  phone: 'Telefone',
  reprocess: 'Reprocessar',
  status: 'Status',
  topic: 'Tópico',
  reprocessAll: 'Reprocessar todas',
  payment: 'Pagamento',
  shipmentDate: 'Data do envio',
  trackingCode: 'Código de rastreio',
  nf: 'Nota fiscal',
  provider: 'Fornecedor',
  freight: 'Frete',
  execution: 'Execução',
  deadline: 'Prazo',
  method: 'Método',
  deliveryPrice: 'Custo de envio',
  subtotal: 'Subtotal',
  invoice: 'Fatura',
  thankYouForYourPurchase: 'Obrigado pela sua compra',
  others: 'Outros',
  additionalInfos: 'Informações adicionais',
  trackYourOrderThroughTheSite: 'Acompanhe o seu pedido pelo site',
  cnpj: 'CNPJ',
  export: 'Exportar',
  shippingDeadline: 'Prazo do envio',
  eventDeadline: 'Prazo do evento',
  productionDeadline: 'Prazo de produção',
  days: 'Dias',
  workingDays: 'Dias úteis',
  shippingTime: 'Prazo de transporte',
  eventDate: 'Data do evento',
  productionDays: 'Prazo de produção (dias)',
  search: 'Pesquisa',
  pressEnterToSearch: 'Pressione Enter para executar a pesquisa',
  NATURAL: 'Pessoa física',
  LEGAL: 'Pessoa jurídica',
  naturalPerson: 'Pessoa física',
  legalPerson: 'Pessoa jurídica',
  bgColor: 'Cor de fundo',
  'OrderStatus.Sort.Date': 'Data de criação',
  'OrderStatus.Sort.EventDate': 'Data do evento',
  'OrderStatus.Sort.ProductionDeadline': 'Prazo de produção',
  'OrderStatus.Sort.ArrivalInStatus': 'Chegada no status',
  'OrderStatus.Sort.LastInteraction': 'Ultima interação',
  'OrderStatus.Sort.LastWhatsMessage': 'Ultimo Whats',
  sort: 'Ordenar',
  ordination: 'Ordenação',
  sectors: 'Setores',
  sector: 'Setor',
  statusBgColor: 'Cor de fundo do status',
  cardBgColor: 'Cor de fundo do item',
  cardTextColor: 'Cor do texto',
  stateRegistration: 'Inscrição estadual',
  selectAnSector: 'Selecione um setor...',
  funnel: 'Funil',
  settings: 'Configurações',
  requestSector: 'Solicitar setor',
  seeActivities: 'Ver atividades',
  activities: 'Atividades',
  orderActivityCommentFieldPlaceholder: 'Insira um comentário e pressione Ctrl+Enter para salvar',
  noActivitiesFound: 'Nenhuma atividade encontrada',
  shipmentCost: 'Custo do frete',
  mailTag: 'Etiqueta',
  selectTheLayout: 'Selecione o layout',
  change: 'Alterar',
  general: 'Geral',
  payments: 'Pagamentos',
  validated: 'Validado',
  parcel: 'Parcela',
  confirmed: 'Confirmado',
  notConfirmed: 'Não confirmado',
  remainingValue: 'Valor restante',
  paidValue: 'Valor pago',
  layout: 'Layout',
  size: 'Tamanho',
  configureTypes: 'Configurar tipos',
  CANCELLED: 'Desistência',
  CANCELLATION: 'Desistência',
  SALE: 'Venda',
  FINISHED: 'Finalizado',
  statusType: 'Tipo do status',
  statusTypes: 'Tipos dos status',
  financial: 'Financeiro',
  currentMonth: 'Mês atual',
  currentYear: 'Ano atual',
  numberOfOrders: 'Numero de pedidos',
  valueOfOrders: 'Valor dos pedidos',
  budgetedAmount: 'Valor orçado',
  valueSold: 'Valor vendido',
  dropouts: 'Desistências',
  chargedShipment: 'Frete cobrado',
  lastMonth: 'Mês passado',
  lastWeek: 'Semana passada',
  currentWeek: 'Semana atual',
  sales: 'Vendas',
  budgets: 'Orçamentos',
  numberOfBudgets: 'Número de orçamentos',
  waiting: 'Aguardando',
  inProgress: 'Em andamento',
  onBudget: 'Em orçamento',
  toggleAlert: 'Ativa/desativa alerta',
  accountingEntries: 'Lançamentos contábeis',
  accountingEntry: 'Lançamento contábil',
  financialDashboard: 'Dashboard financeiro',
  category: 'Categoria',
  subcategory: 'Subcategoria',
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
  companyCosts: 'Custos da empresa',
  report: 'Relatório',
  reports: 'Relatórios',
  financialReport: 'Relatório financeiro',
  confirmeds: 'Confirmados',
  notConfirmeds: 'Não confirmados',
  condition: 'Condição',
  confirmation: 'Confirmação',
  confirmationDate: 'Data confirmação',
  paymentConfirmationMessage: 'Você confirma a(o) {method} de valor {value} do cliente {name}',
  street: 'Rua',
  providers: 'Fornecedores',
  fetch: 'Fornecedores',
  loadLeft: 'Carregar restante',
  withdrawal: 'Desistência',
  reasons: 'Motivos',
  message: 'Mensagem',
  fillWithdrawalErrorMessage:
    'É necessário preencher o motivo da deistência para poder salvar o pedido',
  withdrawalMessagePlaceholder: 'Detalhes adicionais da desistência',
  cost: 'Custo',
  application: 'Aplicação',
  art: 'Arte',
  designWorkflow: 'Workflow de design',
  LOW: 'Baixa',
  MID: 'Média',
  HIGH: 'Alta',
  NOT_STARTED: 'Não iniciado',
  IN_PROGRESS: 'Em andamento',
  priority: 'Prioridade',
  responsible: 'Responsável',
  timeline: 'Timeline',
  seller: 'Vendedor',
  SELLER: 'VENDEDOR',
  DESIGNER: 'Designer',
  queue: 'Fila',
  designer: 'Designer',
  upload: 'Upload',
  front: 'Frente',
  back: 'Verso',
  design: 'Design',
  file: 'Arquivo',
  files: 'Arquivos',
  image: 'Imagem',
  preview: 'Preview',
  events: 'Eventos',
  startDate: 'Data inicial',
  endDate: 'Data final',
  source: 'Origem',
  birthdate: 'Data de nascimento',
  customerType: 'Papel',
  gender: 'Gênero',
  eventTypes: 'Eventos',
  eventType: 'Evento',
  duplicate: 'Duplicar',
  note: 'Observação',
  filters: 'Filtros',
  withoutShippingCost: 'Sem custo de frete',
  withoutPayments: 'Sem pagamentos',
  filtrate: 'Filtrar',
  filter: 'Filtro',
  dateField: 'Campo de data',
  orderDate: 'Data do pedido',
  statusAutomation: 'Automação de Status',
  statusAutomations: 'Automações de Status',
  statuses: 'Status',
  interval: 'Intervalo',
  inMinutes: 'Em minutos',
  moveToStatus: 'Mover para o status',
  statusToBeObserved: 'Status a serem observados',
  idleTime: 'Tempo inativo',
  after: 'Após',
  afterIdleTimeInMinutes: 'Após tempo inativo (em minutos)',
  initialCep: 'CEP inicial',
  finalCep: 'CEP final',
  default: 'Padrão',
  cepConfigs: 'Configurações de CEP',
  defaultShippingTimeInDays: 'Prazo de transporte padrão (em dias)',
  shippingTimeInDays: 'Prazo de transporte (em dias)',
  bands: 'Faixas',
  numberOfBands: 'Número de faixas',
  moveToFinishedStatusWarnMessage:
    'Você não possui permissão para mover um pedido que não esta com 100% do valor pago para um status finalizado',
  statusChange: 'Mudança de status',
  STATUS_CHANGE: 'Mudança de status',
  notFound: 'Não encontrado',
  site: 'Site',
  sender: 'Remetente',
  addressee: 'Destinatário',
  productionDeadlineDelivery: 'Prazo de produção/entrega',
  afterPayment: 'Após pagamento',
  MESSAGE: 'Mensagem',
  see: 'Ver',
  withPayment: 'Com pagamentos',
  all: 'Todos',
  ranges: 'Faixas',
  quantityRanges: 'Faixas de quantidade',
  min: 'Minimo',
  max: 'Maximo',
  postalCodeRangeNotFound:
    'Faixa de CEP não encontrada no meio de entrega selecionado, aplicado valor padrão',
  selectSomeCustomer: 'Selecione um cliente',
  noAddressFoundForSelectedCustomer: 'Nenhum endereço encontrado para o cliente selecionado',
  selectSomeShippingMethod: 'Selecione um meio de entrega',
  quantityRangeNotFound: 'Faixa de quantidade não encontrada, aplicado valor padrão',
  searchShippingInfo: 'Pesquisar prazo e valor nas faixas de cep e quantidade cadastradas',
  defaultShippingTime: 'Prazo de transporte padrão',
  defaultValue: 'Valor padrão',
  customerShippingMethodWarningMessage:
    'As faixas de CEP do meio de entrega selecionado não incluem o CEP do cliente',
  uninformed: 'Não informado',
  withShippingCost: 'Com custo de frete',
  withProductCost: 'Com custo de produto',
  itemQuantityGreaterThan: 'Quantidade de itens maior que',
  itemQuantityLessThan: 'Quantidade de itens menor que',
  showInvisibleStatuses: 'Mostrar status invisíveis',
  daysToDelivery: 'Dias até a entrega',
  daysToDeliveryLabel: 'Quantos dias demorou a entrega',
  countOnShipping: 'Contar no frete',
  trackingLink: 'Link de rastreamento',
  trackingLinkHint:
    'Pode ser adicionada a variável ${code} ao link para substituir pelo código do pedido',
  generalDiscount: 'Desconto geral',
  band: 'Faixa',
  partial: 'Parcial',
  onlyPartial: 'Somente parcial',
  onlyTotal: 'Somente total',
  valueOfItems: 'Valor dos itens',
  costOfFreight: 'Valor do frete',
  quantityOfItems: 'Quantidade de itens',
  discount: 'Desconto',
  totalValue: 'Valor total',
  useNewOrderExportLayout: 'Utilizar novo layout na exportação de pedidos',
  topSellingProducts: 'Produtos mais vendidos',
  defaultImage: 'Imagem padrão',
  images: 'Imagens',
  imageByAttribute: 'Imagem por atributo',
  costOfProducts: 'Custo dos produtos',
  ownProduction: 'Produção própria',
  rawMaterial: 'Matéria-prima',
  stock: 'Estoque',
  IN: 'Entrada',
  OUT: 'Saída',
  operation: 'Operação',
  notes: 'Observações',
  designChanges: 'Alterações artes',
  designChangeUser: 'Usuário que alterou a arte',
  eventProspections: 'Prospecção de Eventos',
  prospections: 'Prospecções',
  addProspection: 'Adicionar prospecções',
  eventProspection: 'Prospecção de Evento',
  withoutProspections: 'Sem prospecções',
  whoCreatedTheDesign: 'Quem criou a arte',
  accountingEntryCategories: 'Categorias (Lanc. contábeis)',
  accountingEntryCategory: 'Categoria (Lanc. contábeis)',
  subcategories: 'Subcategorias',
  addSubcategory: 'Adicionar subcategoria',
  totalValuePlusShipping: 'Valor total + frete',
  validationError: 'Erro na validação dos dados',
  shippingValueValidationMessage:
    'O campo "Valor do frete" esta com um valor muito alto ({value}), essa informação esta correta?',
  shippingCostValidationMessage:
    'O campo "Custo do frete" esta com um valor muito alto ({value}), essa informação esta correta?',
  daysToDeliveryValidationMessage:
    'O campo "Quantos dias demorou a entrega" esta com um valor muito alto ({value}), essa informação esta correta?',
  funnels: 'Funis',
  previous: 'Anterior',
  current: 'Atual',
  next: 'Próximo',
  caption: 'Nome visualização',
  attributeValue: 'Valor do atributo',
  materials: 'Materiais',
  material: 'Material',
  productionFinished: 'Produção finalizada',
  productionSchedule: 'Cronograma de produção',
  PRODUCTION: 'Produção',
  compound: 'Composto',
  late: 'Atrasado',
  details: 'Detalhes',
  withoutAttributes: 'Sem atributos',
  day: 'Dia',
  notBusinessDay: 'Não é dia útil',
  productionUsage: 'Uso da produção',
  production: 'Produção',
  suggestion: 'Sugestão',
  end: 'Fim',
  layouts: 'Layouts',
  requestApproval: 'Solicitar aprovação',
  orderLayout: 'Layout do pedido',
  layoutsApproval: 'Aprovação de layouts',
  approvalRequest: 'Aprovação solicitada',
  approved: 'Aprovado',
  dropTheFilesHere: 'Solte os arquivos aqui',
  dragTheFilesHere: 'Arraste os arquivos aqui',
  clickToDownloadFile: 'Clique para baixar o arquivo',
  approve: 'Aprovar',
  reject: 'Rejeitar',
  uploads: 'Uploads',
  uploadSizeExceeded: 'O arquivo {name} excedeu o tamanho limite do upload',
  rejected: 'Rejeitado',
  waitingApproval: 'Aguardando aprovação',
  ribbonAlert: 'Fitas já produzidas',
  orderSearch: 'Pesquisar pedidos',
  URGENT: 'Urgente',
  alert: 'Alerta',
  orderLayoutRequests: 'Solicitações de design',
  layoutRequests: 'Solicitações de design',
  layoutRequest: 'Solicitação de design',
  layoutResponse: 'Resposta de design',
  orderLayoutResponses: 'Designs produzidos',
  requester: 'Solicitante',
  noFilesAroundHere: 'Sem arquivos por aqui',
  alertTheSeller: 'Alertar o vendedor',
  alertTheDesigners: 'Alertar os designers',
  finalizeRequest: 'Finalizar solicitação',
  requestDesign: 'Solicitar design',
  noRequestsFound: 'Nenhuma solicitação encontrada',
  addDesign: 'Adicionar design',
  finished: 'Finalizada',
  response: 'Resposta',
  editDesign: 'Editar design',
  editRequest: 'Editar solicitação',
  noLayoutRequestFound: 'Nenhuma solicitação de design encontrada',
  finishedDesigns: 'Desings finalizados',
  lastDesigns: 'Ultimos designs',
  lastModification: 'Ultima modificação',
  salesByState: 'Vendas por estado',
  updateValues: 'Atualizar valores',
  percent: 'Percentual',
  customersByOrigin: 'Clientes por origem',
  withSource: 'Com origem',
  withoutSource: 'Sem origem',
  paintAlert: 'Já pintado',
  drawnAlert: 'Já repuxado',
  costs: 'Custos',
  discountValue: 'Valor dos descontos',
  itemsValue: 'Valor dos itens',
  productsValue: 'Valor dos produtos',
  discountsGiven: 'Descontos concedidos',
  totalGrossValue: 'Valor total (bruto)',
  totalNetValue: 'Valor total (líquido)',
  totalCosts: 'Custos totais',
  discounts: 'Descontos',
  newEvent: 'Novo evento',
  event: 'Evento',
  information: 'Informações',
  prospection: 'Prospecção',
  prospected: 'Prospectados',
  notProspected: 'Não prospectados',
  mandeUmZapToken: 'Mande um zap (Token)',
  ok: 'Ok',
  reviewConfigParamsWarning: 'Revise os parâmetros de configuração e tente novamente',
  connectionSuccessfullyEstablished: 'Conexão estabelecida com sucesso',
  validate: 'Validar',
  validateConfig: 'Validar configuração',
  mandeUmZap: 'MandeUmZap',
  firstPaymentAvgTitle: 'Média de dias até o 1º pagamento',
  firstPaymentAvgTooltip:
    'Média do número de dias desde a criação do pedido até a data do primeiro pagamento, clique para ver mais detalhes',
  send: 'Enviar',
  whatsInputPlaceholder: 'ENTER para enviar a mensagem (SHIFT+ENTER para nova linha)',
  averageTicket: 'Ticket médio',
  whatsApp: 'WhatsApp',
  whatsAppContactNotFoundMessage:
    'Não foi possível acessar as informações do WhatsApp para este cliente, verifique se o número do celular está cadastrado corretamente',
  viewAll: 'Ver todas',
  answer: 'Responder',
  quizOrderSourceLabel: 'Como nos conheceu?',
  quizAttendanceSpeedLabel: 'O atendimento foi ágil?',
  quizProductionSpeedLabel: 'Qual sua opinião sobre o prazo de produção?',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  GOOGLE: 'Google',
  RECOMMENDATION: 'Indicação',
  TOO_SLOW: 'Muito lento',
  SLOW: 'Lento',
  REGULAR: 'Normal',
  FAST: 'Rápido',
  VERY_FAST: 'Muito rápido',
  quizFreightCostLabel: 'Sobre o custo de frete, você considera',
  CHEAP: 'Barato',
  AS_EXPECTED: 'Dentro do esperado',
  EXPENSIVE: 'Caro',
  VERY_EXPENSIVE: 'Muito caro',
  quizProductQualityLabel: 'Qualidade dos produtos',
  productQuality: 'Qualidade dos produtos',
  quizCustomizationQualityLabel: 'A personalização ficou dentro do esperado?',
  EXPECTED_MORE: 'Esperava mais',
  YES: 'Sim',
  HIGHER_THAN_EXPECTED: 'Acima do esperado',
  quizShoppingExperienceLabel: 'O que achou da experiência de compra no geral?',
  BAD: 'Ruim',
  GOOD: 'Boa',
  VERY_GOOD: 'Muito boa',
  quizNotesLabel: 'Caso queira abrir o coração reservamos esse espaço pra vc 🙃',
  satisfactionSurvey: 'Pesquisa de satisfação',
  OTHER: 'Outro',
  sendAnswersConfirmation: 'Tem certeza que deseja enviar essas respostas?',
  sendAnswers: 'Enviar respostas',
  thanksForYourAnswer: 'Obrigado por sua resposta',
  quizNotFound: 'Ops, não encontramos nada por aqui...',
  researches: 'Pesquisas',
  afterSaleQuizLink: 'Link para a pesquisa de pós-venda',
  create: 'Criar',
  answered: 'Respondida',
  answers: 'Respostas',
  freightCost: 'Custo do frete',
  shoppingExperience: 'Experiência de compra',
  discountCoupons: 'Cupons de desconto',
  redeemed: 'Resgatado',
  suggestedDiscount: 'Desconto sugerido',
  AfterSaleQuiz: 'Questionário pós-venda',
  Recommendation: 'Indicação',
  notificationsSent: 'Notificações enviadas',
  resend: 'Reenviar',
  notifications: 'Notificações',
  apply: 'Aplicar',
  coupon: 'Cupom',
  orderId: 'Pedido ID',
  recommendedBy: 'Recomendado por',
  codeNotFound: 'Código não encontrado',
  recommendationCode: 'Código da indicação',
  SendAfterSaleQuizLink: 'Enviar link do questionário de pós-venda',
  action: 'Ação',
  Nothing: 'Nada',
  couponAlreadyUsed: 'Cupom já utilizado',
  pesquisas: 'Pesquisas',
  afterSaleQuizReport: 'Relatório de Pós-venda',
  orderSourceLabel: 'Origem do pedido',
  attendanceSpeedLabel: 'Agilidade do atendimento',
  freightCostLabel: 'Custo do frete',
  productionSpeedLabel: 'Prazo de produção',
  productQualityLabel: 'Qualidade dos produtos',
  shoppingExperienceLabel: 'Experiência de compra',
  customizationQualityLabel: 'Personalização dentro do esperado',
  responses: 'Respostas',
  finalValue: 'Valor final',
  sendAfterSaleQuizLinkMessagePlaceholder:
    'A variável ${link} no texto será substituída pelo link do questionário. Ex: Olá, acesse o link  para responder o questionário: ${link}',
  SendWhatsMessage: 'Enviar mensagem via Whatsapp',
  updatedAt: 'Ultima atualização',
  lastInteractionOn: 'Ultima interação',
  sendOnlyOnceForEachCustomer: 'Enviar somente uma vez para cada cliente',
  sendOnlyToCustomerWithoutPurchases: 'Enviar somente para clientes sem compras',
  clearCustomerList: 'Limpar lista de clientes',
  cancelledOrdersReport: 'Rel motivos de desistência',
  cancelledOrders: 'Pedidos cancelados',
  productPriceLabel: 'Você considera o preço dos produtos',
  notaFiscal: 'Nota fiscal',
  natureza_operacao: 'Natureza da operação',
  data_emissao: 'Data emissão',
  data_entrada_saida: 'Data entrada/saida',
  tipo_documento: 'Tipo documento',
  finalidade_emissao: 'Finalidade emissão',
  categories: 'Categorias',
  lastWhatsMessage: 'Ultimo Whats',
  priceList: 'Tabela de preço',
  add: 'Adicionar',
  addBand: 'Adicionar faixa',
  applyPriceListTooltip: 'Aplicar o valor desta faixa',
  initialContacts: 'Contatos whats',
  generateOrder: 'Gerar pedido',
  showInitialContacts: 'Mostrar contatos whats',
  uniquePhoneNumberValidation: 'Este telefone já esta sendo utilizado em outro contato/cliente',
  noItemsFoundForTheAppliedFilter: 'Nenhum item encontrado para o filtro aplicado',
  withoutPhone: 'Sem telefone',
  continue: 'Continuar',
  newCustomerWizardTitle: 'Novo cliente? Insira o telefone',
  duplicatedContacts: 'Contatos duplicados',
  selectAnPhone: 'Selecione um telefone',
  whatsNotePlaceholder: 'Escreva algo importante por aqui 🙃',
  messageSaveSuccess: 'Mensagem salva com sucesso',
  budget: 'Orçamento',
  sendBudgetToCustomer: 'Enviar orçamento para o cliente',
  sendBudgetToCustomerConfirmMessage: 'Tem certeza que deseja enviar o orçamento para o cliente?',
  budgetSentSuccessfully: 'Orçamento enviado com sucesso',
  reply: 'Responder',
  main: 'Principal',
  markAsPrincipal: 'Marcar como principal',
  roles: 'Papéis',
  manageOrder: 'Gerenciar pedido',
  userQueue: 'Fila do usuário',
  basePrice: 'Preço base',
  you: 'Você',
  reloadMessages: 'Recarregar mensagens',
  noOne: 'Ninguém',
  whatsQueue: 'Fila Whats',
  Default: 'Padrão',
  ByGreatestWaitingTime: 'Maior tempo de espera',
  ChatView: 'Visualizou o chat',
  MessageSent: 'Enviou uma mensagem',
  WhatsActivity: 'Atividade no Whats',
  clickToEditTag: 'Clique para editar a tag',
  unlinkTag: 'Desvincular Tag',
  removeTag: 'Remover Tag',
  tagName: 'Nome da Tag',
  clickToApplyTag: 'Clique para aplicar a tag',
  emptyAroundHere: 'Esta vazio por aqui',
  tag: 'Tag',
  tags: 'Tags',
  syncWhats: 'Sincronizar com o MandeUmZap',
  newQueue: 'Nova fila',
  removeThisQueue: 'Remover esta fila',
  title: 'Título',
  clickToEdit: 'Clique para editar',
  enterToSaveEscToCancel: 'Pressione ENTER para salvar ou ESC para cancelar',
  lastResponse: 'Última resposta',
  lastMessage: 'Última mensagem',
  read: 'Lido',
  notRead: 'Não lido',
  markAsRead: 'Marcar como lido',
  markAsNotRead: 'Marcar como não lido',
  markedAsNotReadAt: 'Marcado como não lido em',
  markedAsReadAt: 'Marcado como lido em',
  'InitialContactUi.SortOpts.Default': 'Ultima mensagem (Cliente)',
  'InitialContactUi.SortOpts.ByGreatestWaitingTime': 'Aguardando resposta por mais tempo',
  'InitialContactUi.SortOpts.ArrivalInStatus': 'Chegada no status (Pedidos)',
  'InitialContactUi.SortOpts.ByLastResponse': 'Ultima resposta (Sistema)',
  arrivedInStatusAt: 'Chegou no status em',
  sendingMessage: 'Enviando mensagem',
  toggleDarkMode: 'Habilita/desabilita dark mode',
  maintenance: 'Manutenção',
  keepOnlyLastDays: 'Manter os últimos {days} dias',
  portfolio: 'Portfólio',
  whatsMessagesInitialContactPlaceholder:
    'Digite uma mensagem customizada para ser enviada ao cliente ao entrar em contato a primeira vez pelo Whatsapp (ou deixe em branco para manter a mensagem padrão)',
  clear: 'Limpar',
  portfolioUrl: 'Link do portfólio',
  searchByPhone: 'Pesquisar por telefone',
  hideAlreadyAnswered: 'Não mostrar já respondidas',
  totalCost: 'Custo total',
  productCostsAndSalesReport: 'Rel custos x vendas produtos',
  dateRangeLabel: 'Faixa de período',
  generate: 'Gerar',
  sellersReport: 'Rel vendas por vendedor',
  orderCostsAndSalesReport: 'Rel custos x vendas pedidos',
  signal: 'Sinal',
  receipt: 'Comprovante',
  providerPaymentDate: 'Data pagamento fornecedor',
  withPaidProvider: 'Fornecedor pago',
  internalUse: 'Uso interno',
  sendInitialMessage: 'Enviar mensagem inicial',
  template: 'Template',
  createNewMessageTemplate: 'Novo template de mensagem',
  messageTemplate: 'Template de mensagem',
  messageTemplateEmpty: 'Nenhum template encontrado',
  containText: 'Contém mensagem',
  containImage: 'Contém imagem',
  providerPayments: 'Pagamento fornecedores',
  paymentDate: 'Data de pagamento',
  providerPaymentConfirmationMsg: 'Você confirma o pagamento do valor {value} ao fornecedor {name} no dia {date} para o pedido {orderCode}?',
  providerPaymentRemoveConfirmationMsg: 'Tem certeza que deseja remover a confirmação de pagamento do valor {value} para o fornecedor {name} no pedido {orderCode}?',
  withoutProvider: 'Sem fornecedor',
  confirmPaymentOnCurrentDay: 'Confirma o pagamento no dia atual',
  bugdetValidMsg: 'Orçamento válido por 20 dias',
  bugdetGeneratedDate: 'Data do orçamento',
  orderFullyPaid: 'Pedido totalmente pago',
  dayLevel: 'Nível de dia',
  orderMissingPayments: 'Pedidos faltando pagamento',
  answerDate: 'Data da resposta',
};
